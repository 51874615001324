import React, { useRef } from "react";
import { EnvelopeIcon, HomeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import emailjs from "@emailjs/browser";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const form = useRef();
  const captcha = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!token) {
      setError("Please verify the captcha");
    } else {
      await emailjs
        .sendForm(
          "service_ijtke48",
          "template_smqkhvq",
          form.current,
          "-3HI8xMzKj6KgljwH"
        )
        .then(
          (result) => {
            setError("");
          },
          (error) => {
            setError("Please try again later");
          }
        );
      captcha.current.resetCaptcha();
      setToken("");
    }
    setLoading(false);
  };

  return (
    <div className="mt-10">
      <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">{t("Contact us")}</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative overflow-hidden bg-[#59200B] py-10 px-6 sm:px-10 xl:p-12">
              <div
                className="pointer-events-none absolute inset-0 sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">
                {t("Contact information")}
              </h3>
              <p className="mt-6 text-sm text-indigo-50">
                {t("contact description1")}
                <br />
                {t("contact description2")}
                <br />
                <br />
                {t("contact description3")}
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">{t("Address")}</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <HomeIcon
                    className="h-6 w-6 flex-shrink-0text-white"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Provence - Marrakech</span>
                </dd>

                <dt>
                  <span className="sr-only">{t("Phone number")}</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <PhoneIcon
                    className="h-6 w-6 flex-shrink-0text-white"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+33 628 806 569</span>
                </dd>
                <dt>
                  <span className="sr-only">{t("Email")}</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <EnvelopeIcon
                    className="h-6 w-6 flex-shrink-0text-white"
                    aria-hidden="true"
                  />
                  <span className="ml-3">view.prod.dm@gmail.com</span>
                </dd>
                <dt>
                  <span className="sr-only">Instagram</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <img
                    src="/images/SVG/insta.svg"
                    className="h-6 w-6 flex-shrink-0text-white"
                    alt="Instagram"
                  />
                  <span className="ml-3">
                    <a
                      href="https://www.instagram.com/viewfilms.w/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      viewfilms.w
                    </a>
                  </span>
                </dd>
              </dl>
            </div>

            {/* Contact form */}
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">
                {t("Send us a message")}
              </h3>
              <form
                action="#"
                method="POST"
                className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                ref={form}
                onSubmit={sendEmail}
              >
                <div className="sm:col-span-2">
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-900"
                  >
                    {t("Im Interested In")}
                  </label>
                  <div className="mt-1">
                    <select
                      type="text"
                      name="subject"
                      id="subject"
                      className="block w-full rounded-md border-[1px] border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
                    >
                      <option value="">---</option>
                      <option value="PHOTOGRAPHY">{t("PHOTOGRAPHY")}</option>
                      <option value="VIDEOGRAPHY">{t("VIDEOGRAPHY")}</option>
                      <option value="BOTH PHOTO + VIDEO">
                        {t("BOTH PHOTO VIDEO")}
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-900"
                  >
                    {t("First name")}
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-[1px] border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-900"
                  >
                    {t("Last name")}
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-[1px] border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-900"
                  >
                    {t("Email")}
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-[1px] border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
                      required
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-900"
                    >
                      {t("Phone")}
                    </label>
                    <span id="phone-optional" className="text-sm text-gray-500">
                      {t("Optional")}
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      className="block w-full rounded-md border-[1px] border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="place"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Getting Married At
                    </label>
                    {/* <span id="budget-optional" className="text-sm text-gray-500">
                      {t("Optional")}
                    </span> */}
                  </div>
                  <div className="mt-1">
                    <div class="flex">
                      <input
                        type="text"
                        name="place"
                        id="place"
                        className="block w-full rounded-md border-[1px] border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
                        required
                      />
                    </div>

                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="budget"
                      className="block text-sm font-medium text-gray-900"
                    >
                      {t("Budget")}
                    </label>
                    {/* <span id="budget-optional" className="text-sm text-gray-500">
                      {t("Optional")}
                    </span> */}
                  </div>
                  <div className="mt-1">
                    <div class="flex">
                      <input
                        type="number"
                        name="budget"
                        id="budget"
                        className="block w-full rounded-l-md border-[1px] border-gray-300 py-3 px-4 text-gray-900 shadow-sm border-r-0"
                        required
                      />
                      <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-[#FBF7ED] border border-l-0 border-gray-300 rounded-r-md">
                        <svg class="w-6 h-6 text-[#693623]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </div>

                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Message
                    </label>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className="block w-full rounded-md border-[1px] border-gray-300 py-3 px-4 text-gray-900 shadow-sm"
                      required
                      defaultValue={""}
                    />
                  </div>
                </div>
                {error && (
                  <p
                    className="sm:col-span-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    {error}
                  </p>
                )}
                <HCaptcha
                  ref={captcha}
                  sitekey="283941d9-0419-4e47-9b1f-bdb42ff6e2e8"
                  onVerify={(token) => setToken(token)}
                  onExpire={(e) => setToken("")}
                ></HCaptcha>
                <div className="text-right">
                  <button
                    type="submit"
                    className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-[#59200B] px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-[#59200B]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto disabled:bg-[#59200B]/50"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>{" "}
                        {t("Sending")}...
                      </>
                    ) : (
                      <>{t("Submit")}</>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
