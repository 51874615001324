export const videos = [
  // {
  //   video: "https://player.vimeo.com/video/784594074?h=7f144d7e17",
  //   title: "D&A",
  // },
  {
    video: "https://player.vimeo.com/video/728563066?h=b307b9f90a",
    title: "EL PARADISO",
  },
  {
    video: "https://player.vimeo.com/video/728562296?h=aebba6f9e7",
    title: "La Nuova Dolce Vita",
  },
  {
    video: "https://player.vimeo.com/video/784577566?h=377e9b4265",
    title: "Passion in Province",
  },
  // {
  //   video: "https://player.vimeo.com/video/784581239?h=adfaa776bb",
  //   title: "S&M",
  // },
  // {
  //   video: "https://player.vimeo.com/video/573680689?h=d2e428a19e",
  //   title: "Sound of waves",
  // },
];

export const sliderArr = [
  {
    id: "saint-jean-cap-ferrat",
    title: "Saint Jean Cap-Ferrat",
    videoUrl: "https://res.cloudinary.com/dt6rowwfl/video/upload/v1734723714/luxury_wedding_at_saint_jean_cap_ferrat_anna___tom_evqtro.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1032794055?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    fallbackImageUrl: "https://res.cloudinary.com/dt6rowwfl/image/upload/v1734723714/luxury_wedding_at_saint_jean_cap_ferrat_anna___tom.jpg",
    infos: [
      {
        title: "Venue",
        content: "saint-jean-cap-ferrat-Planner"
      },
      {
        title: "Planner",
        content: "The bride wanted to organize her wedding by herself, she thought about every detail and was guided and helped by the hotel event team."
      },
      {
        title: "Story",
        content: "saint-jean-cap-ferrat-Story"
        // content: `Anna and Tom’s wedding at the luxurious Hôtel Saint-Jean-Cap-Ferrat was a breathtaking
        //           celebration of love and elegance, set on the stunning French Riviera. Surrounded by the serene
        //           Mediterranean Sea, The Hôtel Saint-Jean-Cap-Ferrat exuded timeless charm, with its lush
        //           gardens, sparkling pools, and breathtaking views of the coastline. The couple’s ceremony took
        //           place in the hotel's beautifully landscaped gardens, adorned with white roses and soft, romantic
        //           lighting, creating an intimate and magical atmosphere. Anna looked radiant in a delicate lace
        //           gown, while Tom stood beside her in a tailored suit, both beaming with joy. After exchanging
        //           vows, guests enjoyed a cocktail reception on the terrace, overlooking the glistening sea. The
        //           evening continued with an exquisite dinner inside the hotel’s elegant dining room, where soft
        //           music played and laughter filled the air. The night was capped off with a dazzling display of
        //           fireworks over the Mediterranean, marking the start of Anna and Tom’s unforgettable journey
        //           together.`
      },
    ]
  },
  {
    id: "la-mamounia",
    title: "La Mamounia",
    videoUrl: "https://res.cloudinary.com/dt6rowwfl/video/upload/v1734723715/magical_wedding_at_la_mamounia_marrakech_x3zraf.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1032798257?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    fallbackImageUrl: "https://res.cloudinary.com/dt6rowwfl/image/upload/v1734723713/magical_wedding_at_la_mamounia_marrakech.jpg",
    infos: [
      {
        title: "Venue",
        content: "La Mamounia Marrakech"
      },
      {
        title: "Planner",
        content: "Daylove Event"
      },
      {
        title: "Story",
        content: "la-mamounia-Story"
        // content: `Maryline and Alexandre's wedding at the legendary Mamounia in Marrakech was a
        //           celebration straight out of a fairy tale, blending the spirit of Marrakech with modern elegance. A
        //           destination wedding, nestled in the heart of the Red City, the lush gardens and intricate
        //           architecture of the Mamounia provided an unforgettable backdrop for their special day.<br/><br/>
        //           The welcome dinner took place under the sumptuous trees of the Mamounia gardens, a large
        //           table decorated with hundreds of flowers, lanterns hung from the trees lit with soft light to make
        //           the beautiful table decorations shine.<br/><br/>
        //           The next day for the wedding, the ceremony took place in the luxurious gardens of the Mamounia,
        //           with fragrant orange blossoms in the air. Maryline, radiant in her stunning gown designed by a
        //           talented couturier, walked down the aisle adorned with petals and traditional Moroccan lanterns,
        //           while Alexandre waited for her with a joyful smile, dressed in an exquisite suit. Their vows were
        //           exchanged in front of their loved ones, framed by the timeless beauty of La Mamounia’s iconic
        //           arches and fountains.<br/><br/>
        //           The celebration continued with a luxurious dinner under the stars in the palace’s enchanting
        //           courtyard, where long tables were decorated with touches of gold, flickering candles and lush
        //           floral arrangements. Guests enjoyed an exquisite menu that combined Moroccan flavors with
        //           international cuisine.<br/><br/>
        //           As night fell, the party came alive with dancing under a canopy of twinkling lights in La
        //           Mamounia’s club. The energy, laughter and love shared by Maryline, Alexandre and their guests
        //           made their wedding at La Mamounia an unforgettable experience filled with magic and romance.<br/><br/>
        //           Marrakech is and will always be a great destination for weddings.`
      },
    ]
  },
  {
    id: "kasbah-bab-ourika",
    title: "Kasbah Bab Ourika",
    videoUrl: "https://res.cloudinary.com/dt6rowwfl/video/upload/v1734723715/luxury_wedding_in_moroccos_atlas_mountains_gtangq.mp4",
    vimeoUrl: "https://player.vimeo.com/video/962611618?h=7e36ecd873&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    fallbackImageUrl: "https://res.cloudinary.com/dt6rowwfl/image/upload/v1734723714/luxury_wedding_in_moroccos_atlas_mountains.jpg",
    infos: [
      {
        title: "Venue",
        content: "Kasbah Bab Ourika / Marrakech"
      },
      {
        title: "Planner",
        content: "Philocaly Weddings"
      },
      {
        title: "Story",
        content: "kasbah-bab-ourika-Story"
        // content: `Victoria and Anthony’s wedding at Kasbah Bab Ourika was a stunning celebration of love,
        //           nestled in the heart of Morocco’s Atlas Mountains. This eco-luxury retreat, perched high above the
        //           lush Ourika Valley, offered breathtaking panoramic views of snow-capped peaks, terraced fields,
        //           and traditional Berber villages—a truly magical setting for their special day.<br/><br/>
        //           The ceremony took place outdoors, with a dramatic mountain backdrop and the soft rustling of
        //           olive trees in the breeze. Victoria, glowing in an elegant gown, walked down a aisle lined with
        //           flowers , while Anthony, dashing in a bespoke suit, awaited her at the end of the aisle decorated
        //           with geant flowers. Their heartfelt vows were exchanged as the sun cast golden hues over the
        //           landscape, creating a romantic and unforgettable moment.<br/><br/>
        //           Following the ceremony, guests enjoyed cocktails on the terrace, sipping artisanal cocktails,
        //           accompanied by locally inspired canapés. The reception unfolded in the kasbah’s courtyard, where
        //           a beautiful tables were decorated with natural linens, candles, and vibrant florals, echoing the
        //           colors of the surrounding landscape. A sumptuous dinner featuring fresh, locally sourced dishes
        //           delighted everyone, with flavors that paid homage to Moroccan culinary traditions.<br/><br/>
        //           As the stars emerged in the clear desert sky, the celebration continued with dancing, live music,
        //           and heartfelt toasts. The afterparty was under the “Alice in Festival Land” theme .<br/><br/>
        //           The intimate, relaxed, and elegant atmosphere of this wedding made Victoria and Anthony’s
        //           wedding at Kasbah Bab Ourika a truly unforgettable experience, filled with love, joy, and the
        //           timeless beauty of Morocco.`
      },
    ]
  },
  {
    id: "chateau-de-tourreau",
    title: "Chateau de Tourreau",
    videoUrl: "https://res.cloudinary.com/dt6rowwfl/video/upload/v1734723715/lara___alexandre_u7t5x4.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1032806650?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    fallbackImageUrl: "https://res.cloudinary.com/dt6rowwfl/image/upload/v1734723713/lara___alexandre.jpg",
    infos: [
      {
        title: "Venue",
        content: "Chateau de Tourreau"
      },
      {
        title: "Planner",
        content: "mc2monamour"
      },
      {
        title: "Story",
        content: "chateau-de-tourreau-Story"
        // content: `Lara and Alexandre’s wedding at the Chateau de Tourreau in Provence was a fairytale
        //           celebration set in one of France’s most enchanting venues.<br/><br/>
        //           The ceremony took place in the chateau’s exquisite gardens, where lush greenery, manicured
        //           lawns, and cascading fountains created an atmosphere of romance. Lara, radiant in a flowing
        //           gown designed by Monique Lhuillier, walked down the aisle lined with white roses and pastel
        //           florals, while Alexandre, dapper in a tailored suit, awaited her beneath a stunning floral arch. Their
        //           vows, exchanged under the soft Provençal sun, were heartfelt and moving, surrounded by their
        //           closest family and friends.<br/><br/>
        //           After the ceremony, guests enjoyed a sophisticated cocktail hour by the chateau’s sparkling pool,
        //           sipping on fine French wines and indulging in artisanal canapés inspired by the local cuisine. The
        //           reception was held in the grand courtyard, where elegant tables adorned with crystal chandeliers,
        //           lush floral arrangements, and golden accents reflected the chateau’s regal charm.<br/><br/>
        //           A gourmet multi-course dinner, featuring Provençal delicacies and paired with exquisite wines,
        //           delighted everyone, while soft live music set the perfect tone for the evening. As the sun set, the
        //           chateau’s façade was illuminated, and the night came alive with dancing under the stars.<br/><br/>
        //           Elegant, romantic, and infused with the magic of Provence, their wedding at Chateau de Tourreau
        //           was truly a day to remember.`
      },
    ]
  },
  {
    id: "ic-paris-le-grand",
    title: "IC Paris Le Grand",
    videoUrl: "https://res.cloudinary.com/dt6rowwfl/video/upload/v1734723714/ic_paris_le_grand_op%C3%A9ra_de_paris_odwnyk.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1032813252?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    fallbackImageUrl: "https://res.cloudinary.com/dt6rowwfl/image/upload/v1734723714/ic_paris_le_grand_op%C3%A9ra_de_paris.jpg",
    infos: [
      {
        title: "Venue",
        content: "IC Paris Le Grand"
      },
      {
        title: "Planner",
        content: "Lavie Wedding"
      },
      {
        title: "Story",
        content: "ic-paris-le-grand-Story"
        // content: `Julie and Alexandre’s wedding at the InterContinental Paris Le Grand was a dazzling
        //           celebration of love, elegance, and Parisian charm. Nestled in the heart of the City of Light, this
        //           iconic venue, with its opulent architecture and timeless grandeur, provided the perfect setting for
        //           their unforgettable day.<br/><br/>
        //           The ceremony was held in the elegant Salon Opéra, a breathtaking ballroom adorned with gilded
        //           details, high ceilings, and shimmering chandeliers. Julie, stunning in a sophisticated gown, walked
        //           down an aisle lined . Alexandre, impeccably dressed in a classic tuxedo, waited for her with a
        //           joyful smile, as their vows resonated beautifully in the romantic and luxurious ambiance.<br/><br/>
        //           Following the ceremony, guests were treated to a champagne reception in the hotel’s with a live
        //           classical music created a magical Parisian atmosphere. The celebration continued with a
        //           sumptuous dinner in the grand ballroom, where tables were adorned with crystal glassware,
        //           candlelight, and floral centerpieces reflecting timeless sophistication.<br/><br/>
        //           The culinary experience was nothing short of spectacular, with a menu crafted by the hotel’s
        //           acclaimed chefs, featuring French haute cuisine paired with exquisite wines. As the evening
        //           unfolded, Julie and Alexandre shared their first dance under a canopy of chandeliers, surrounded
        //           by their loved ones.<br/><br/>
        //           Julie and Alexandre’s wedding at the InterContinental Paris Le Grand was a true celebration of
        //           romance and parisien style, forever etched in their memories.`
      },
    ]
  },
  {
    id: "el-fenn-marrakech",
    title: "EL FENN , Marrakech",
    videoUrl: "https://res.cloudinary.com/dt6rowwfl/video/upload/v1734723714/el_fenn_marrakech_yen2lz.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1032816447?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    fallbackImageUrl: "https://res.cloudinary.com/dt6rowwfl/image/upload/v1734723713/el_fenn_marrakech.jpg",
    infos: [
      {
        title: "Venue",
        content: "RIAD EL FENN , Marrakech"
      },
      {
        title: "Planner",
        content: "Philocaly Weddings"
      },
      {
        title: "Story",
        content: "el-fenn-marrakech-Story"
        // content: `Galilea and Chris’s destination wedding at the enchanting Riad El Fenn in Marrakech was a
        //           celebration filled with love, color, and Moroccan charm.<br/><br/>
        //           Galilea and Chris came from Chicago to get married in the beautiful Marrakech .<br/><br/>
        //           The ceremony took place in the lush central courtyard, where the rich green foliage and intricate
        //           Moroccan tilework created a breathtakingly intimate atmosphere. Galilea, radiant in a flowing
        //           bohemian-style gown, walked down an aisle adorned with vibrant roses and flickering lanterns.
        //           Chris, dressed in a chic linen suit, awaited with a smile of joy. Their vows, shared amidst the
        //           warmth of family and friends, were heartfelt and deeply personal.<br/><br/>
        //           After the ceremony, guests gathered on the rooftop terrace for a cocktail hour overlooking the
        //           iconic Marrakech skyline and the distant Atlas Mountains. They sipped on handcrafted cocktails
        //           infused with local flavors and enjoyed a selection of gourmet , blending Moroccan spices with
        //           modern flair.<br/><br/>
        //           The reception was held in a charming open-air setting, where long table were styled with colorful
        //           textiles, brass lanterns, and bold floral centerpieces inspired by the vibrant hues of Marrakech. A
        //           feast of traditional Moroccan dishes .<br/><br/>
        //           As the evening unfolded, the riad came alive with music and dancing under a canopy of stars.
        //           Galilea and Chris’s wedding at Riad El Fenn was a stunning fusion of romance and cultural
        //           richness, leaving everyone with memories to treasure for a lifetime.`
      },
    ]
  },
];

export const galleryVideo = {
  "CHATEAU-DE-LA-BARONNIE": {
    video: "https://player.vimeo.com/video/750062304?h=e53e0b3b43",
    title: "CHATEAU DE LA BARONNIE",
  },
  "CAP-FERRET": {
    video: "https://player.vimeo.com/video/755514477?h=00569abd62",
    title: "CAP FERRET",
  },
  "BOHEME": {
    video: "https://player.vimeo.com/video/784573547?h=42c324bab7",
    title: "BOHEME",
  },
  // "CHATEAU-LAFITTE": {
  //   video: "https://player.vimeo.com/video/784579951?h=b9b5e5a5b6",
  //   title: "CHATEAU LAFITTE",
  // },
  "CAMARGUE": {
    video: "https://player.vimeo.com/video/784575618?h=41a10e4f59",
    title: "CAMARGUE",
  },
  "MARRAKECH": {
    video: "https://player.vimeo.com/video/832939460?h=ab0acfca07",
    title: "MARRAKECH",
  },
  "LYON": {
    video: "https://www.youtube.com/embed/j7nnpotxC7E?si=V4v-2T9zvcxHmSvZ",
    title: "LYON",
  },
  "IC-PARIS": {
    video: "https://www.youtube.com/embed/X1mbsA9mNug?si=BSgAHDwaDodcorZE",
    title: "IC PARIS",
  },
};

export const getVideoObjById = (id) => {
  return sliderArr.find(item => item.id === id);
}

