import './home.css';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Controller, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import VideoOnHover from '../VideoOnHover';
import { sliderArr } from '../../videos';
import { ThemeContext } from '../../ThemeContext';

export default function Home() {
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const { setIsLoading } = useContext(ThemeContext);
  const [activeVideoId, setActiveVideoId] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const handleSlideClick = (index) => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.slideToLoop(index);
    }
  };

  const handleActiveChange = (id) => {
    setActiveVideoId(id);
  };

  return (
    <>
      <div className='h-screen bg-gradient-to-b from-transparent to-black/50 relative top-0 overflow-hidden'>
        <div className='absolute top-[55%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full'>
          <Swiper
            slidesPerView={1.2}
            centeredSlides={true}
            loop={true}
            spaceBetween={30}
            grabCursor={true}
            className="mySwiper"
            modules={[Controller, Navigation, Pagination, Scrollbar, A11y]}
            breakpoints={{
              320: {
                slidesPerView: 1.2,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 40
              },
              1950: {
                slidesPerView: 5,
                spaceBetween: 40
              }
            }}
            ref={swiperRef}
          >
            {sliderArr.map((obj, key) => (
              <SwiperSlide key={key} onClick={() => handleSlideClick(key)}>
                <VideoOnHover
                  videoUrl={obj.videoUrl}
                  fallbackImageUrl={obj.fallbackImageUrl}
                  id={obj.id}
                  onActiveChange={handleActiveChange}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className='p-5 text-white text-center w-full mt-5'>
            <h1 className='text-4xl font-noirblanc'>{t('CREATE MAGIC')}</h1>
            <p className='tracking-[.25em] text-lg font-light'>{t('wedding film')}</p>
          </div>
        </div>
      </div>
    </>
  );
}