import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Gallery as G } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Cloudinary } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { ThemeContext } from '../../../ThemeContext';
import { weddingImages } from '../../../photos';

export default function Gallery() {
    const { id, number } = useParams();
    const [images, setImages] = useState([]);
    const [index, setIndex] = useState(-1);

    const { setIsLoading } = useContext(ThemeContext);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
        // Example of how to fetch images from a Cloudinary folder
        // You'll need to implement this according to your Cloudinary structure
        // Initialize Cloudinary
        const cld = new Cloudinary({
            cloud: {
                cloudName: 'dt6rowwfl' // Replace with your cloud name
            }
        });
        const fetchImages = async () => {
            try {
                // This is an example structure - adjust according to your needs
                const imageList = Array.from({ length: parseInt(number) }, (_, i) => ({
                    src: cld.image(`${id}-${i + 1}`)
                        .format("auto").quality("auto").resize(fill("auto", 1920))
                        .toURL(),
                    thumbnail: cld.image(`${id}-${i + 1}`)
                        .format("auto").quality("auto").resize(fill("auto", 1920))
                        .toURL(),
                }));
                setImages(imageList);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
        <div className="mx-auto max-w-2xl py-16 px-4 lg:max-w-7xl lg:gap-x-8 lg:px-8 mt-20">
            <h1 className="text-4xl mb-10 text-center underline underline-offset-8">
                {weddingImages.find(item => item.id === id).title}
            </h1>

            <G
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
                rowHeight={450}
                margin={7}
            />

            {!!currentImage && (
                <Lightbox
                    mainSrc={currentImage.src}
                    nextSrc={nextImage.src}
                    prevSrc={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}
        </div>
    );
}