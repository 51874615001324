import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SeenOn() {
    const { t } = useTranslation();

    // const { setIsLoading } = useContext(ThemeContext);

    // useEffect(() => {
    //     setIsLoading(true);
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 1000);
    // }, []);


    return (
        <div className="mx-auto max-w-2xl py-16 px-4 lg:max-w-7xl lg:gap-x-8 lg:px-8 mt-20">
            <h1 className="text-4xl mb-10 text-center underline underline-offset-8">Seen On</h1>

            <div>
                <h1 className="focus:outline-none xl:text-5xl md:text-3xl text-xl text-center text-gray-800 font-extrabold mb-5 pt-4">{t("seen-on-section1-title")}</h1>
                <p className="focus:outline-none text-base text-center text-gray-600 font-normal xl:w-10/12 xl:mx-auto italic">{t("seen-on-section1-content")}</p>
                <section className="text-gray-700 body-font">
                    <div className="container px-5 py-10 mx-auto flex flex-wrap">
                        <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                            <img alt="feature" className="object-cover object-center h-full w-full" src="/images/otheres/kasbah-bab-ourika-wedding.webp" />
                        </div>
                        <div className="flex flex-col flex-wrap lg:py-6 lg:w-1/2 lg:pl-12 lg:text-left text-center m-auto">
                            <div className="flex flex-col mb-10 lg:items-start items-center justify-center">
                                <div className="inline-flex items-center justify-center mb-5">
                                    <a href='https://www.weddingchicks.com/real-weddings/marrakech-festival/' target='_blank'>
                                        <img src='/images/otheres/LOGO_wedding-chicks.png' width={200} className='m-auto' />
                                    </a>
                                </div>
                                <div className="flex-grow">
                                    <h2 className="text-gray-900 title-font font-medium mb-3 text-xl">Story</h2>
                                    <p className="leading-relaxed text-base text-justify">{t('blog1')}</p>
                                    <a href='https://www.weddingchicks.com/real-weddings/marrakech-festival/' target='_blank' className="mt-3 text-indigo-500 inline-flex items-center cursor-pointer">{t("Read More")}
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-gray-700 body-font border-t border-gray-200">
                    <div className="container px-5 py-10 mx-auto flex flex-wrap">
                        <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                            <img alt="feature" className="object-cover object-center h-full w-full" src="/images/otheres/provence-destination-wedding-marionco.webp" />
                        </div>
                        <div className="flex flex-col flex-wrap lg:py-6 lg:w-1/2 lg:pl-12 lg:text-left text-center m-auto">
                            <div className="flex flex-col mb-10 lg:items-start items-center justify-center">
                                <div className="inline-flex items-center justify-center mb-5">
                                    <a href='https://wedvibes.media/an-elegant-wedding-in-provence-gardens/' target='_blank'>
                                        <img src='/images/otheres/wedvibes-meia.png' width={200} className='m-auto' />
                                    </a>
                                </div>
                                <div className="flex-grow">
                                    <h2 className="text-gray-900 title-font font-medium mb-3 text-xl">Story</h2>
                                    <p className="leading-relaxed text-base text-justify">{t('blog2')}</p>
                                    <a href='https://wedvibes.media/an-elegant-wedding-in-provence-gardens/' target='_blank' className="mt-3 text-indigo-500 inline-flex items-center cursor-pointer">{t("Read More")}
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="pb-16" id='seenPartners'>
                    <div className="container mx-auto pt-16">
                        <div className="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto sm:mb-10 mb-16">

                            <h1 className="focus:outline-none xl:text-5xl md:text-3xl text-xl text-center text-gray-800 font-extrabold mb-5 pt-4">{t("seen-on-section2-title")}</h1>
                            <p className="focus:outline-none text-base text-center text-gray-600 font-normal xl:w-10/12 xl:mx-auto italic">{t("seen-on-section2-content")}</p>
                        </div>
                        <div className="xl:py-16 lg:py-16 md:py-16 sm:py-16 px-15 flex flex-wrap">
                            <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b xl:border-r lg:border-r :border-r border-gray-200 xl:pb-10 pb-16 items-center">
                                <img className="focus:outline-none p-4" src="/images/otheres/logos/GrandHotelDuCapFerrat.png" alt="" role="img" />
                            </div>
                            <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b xl:border-r lg:border-r border-gray-200 xl:pb-10 pb-16 items-center">
                                <img className="focus:outline-nonee p-4" src="/images/otheres/logos/Messardiere.png" alt="" role="img" />
                            </div>
                            <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b border-gray-200 xl:pb-10 pb-16 pt-4 items-center">
                                <img className="focus:outline-nonee p-4" src="/images/otheres/logos/La_Mamounia_Logo.png" alt="" role="img" />
                            </div>
                            <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center lg:border-b xl:border-b lg:border-l xl:border-l border-gray-200 xl:pb-10 pb-16 items-center">
                                <img className="focus:outline-nonee p-4" src="/images/otheres/logos/IC Paris.png" alt="" role="img" />
                            </div>
                            <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-r lg:border-r border-gray-200 xl:pt-10 items-center">
                                <img className="focus:outline-nonee p-4" src="/images/otheres/logos/TIME SQUARE.png" alt="" role="img" />
                            </div>
                            <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-r lg:border-r border-gray-200 xl:pt-10 items-center">
                                <img className="focus:outline-nonee p-4" src="/images/otheres/logos/Tourreau.png" alt="" role="img" />
                            </div>
                            <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pt-10 lg:pt-10 md:pt-2 pt-16">
                                <img className="focus:outline-nonee p-4" src="/images/otheres/logos/SMDT.png" alt="" role="img" />
                            </div>
                            <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pt-10 lg:pt-10 md:pt-2 pt-16 lg:border-l">
                                <img className="focus:outline-nonee p-4" src="/images/otheres/logos/selman-marrakech.png" alt="" role="img" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
