import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Home": "Home",
      "About": "About",
      "Portfolio": "Portfolio",
      "Contact": "Contact",

      "Photography Portfolio": "Portfolio",
      "View details for": "View details for",

      "CREATE MAGIC": "WITH YOU ,WE CREATE MAGIC",
      "wedding film": "Wedding photographer and cinematic wedding film",
      "Contact us": "Contact us",
      "Contact information": "Contact information",
      "contact description1": `Let's make connections, talk about yourself, and your day, and have a great time together.`,

      "contact description2": "We live and breath our beautiful “job” and this is what we want to share with you.",

      "contact description3": "We always try to give you a 48-hour answer. Of course, especially at the wedding. If you have heard nothing after 72h please email us again!",

      "Address": "Address",
      "Phone number": "Phone number",
      "Email": "Email",
      "Send us a message": "Send us a message",
      "Im Interested In": "I'm Interested In",
      "PHOTOGRAPHY": "PHOTOGRAPHY",
      "VIDEOGRAPHY": "VIDEOGRAPHY",
      "BOTH PHOTO VIDEO": "BOTH PHOTO + VIDEO",
      "First name": "First name",
      "Last name": "Last name",
      "Phone": "Phone",
      "Budget": "Budget",
      "Optional": "Optional",
      "Sending": "Sending",
      "Submit": "Submit",
      "Line and Noah": "Line and Noah are a couple who are passionate about image, creativity, and love.",
      "Together we founded": "Together, we founded",

      "about1": 'in 2019 and have poured our passion into making it our "baby."',
      "about2": "Our films and photos are inspired by cinematographic methods and compositions, and we strive to reflect the unique style and personality of each wedding through our work.",
      "about3": "We find the most rewarding part of our job is capturing those tender, timeless moments and turning them into a story that will be told and experienced again and again.",
      "about4": ", we take pride in making every project a reflection of our passion and love. We capture life's simplest moments and make them magical.",
      "about5": "If you want to know more about us, write to us, we will be happy to talk to you.",

      // films
      "Venue": "Venue",
      "Planner": "Planner",
      "Story": "Story",


      // Saint Jean Cap-Ferrat
      "saint-jean-cap-ferrat-Planner": "The bride wanted to organize her wedding by herself, she thought about every detail and was guided and helped by the hotel event team.",
      "saint-jean-cap-ferrat-Story": `Anna and Tom’s wedding at the luxurious Hôtel Saint-Jean-Cap-Ferrat was a breathtaking
                  celebration of love and elegance, set on the stunning French Riviera. Surrounded by the serene
                  Mediterranean Sea, The Hôtel Saint-Jean-Cap-Ferrat exuded timeless charm, with its lush
                  gardens, sparkling pools, and breathtaking views of the coastline. The couple’s ceremony took
                  place in the hotel's beautifully landscaped gardens, adorned with white roses and soft, romantic
                  lighting, creating an intimate and magical atmosphere. Anna looked radiant in a delicate lace
                  gown, while Tom stood beside her in a tailored suit, both beaming with joy. After exchanging
                  vows, guests enjoyed a cocktail reception on the terrace, overlooking the glistening sea. The
                  evening continued with an exquisite dinner inside the hotel’s elegant dining room, where soft
                  music played and laughter filled the air. The night was capped off with a dazzling display of
                  fireworks over the Mediterranean, marking the start of Anna and Tom’s unforgettable journey
                  together.`,

      // La Mamounia
      "la-mamounia-Story": `Maryline and Alexandre's wedding at the legendary Mamounia in Marrakech was a
                              celebration straight out of a fairy tale, blending the spirit of Marrakech with modern elegance. A
                              destination wedding, nestled in the heart of the Red City, the lush gardens and intricate
                              architecture of the Mamounia provided an unforgettable backdrop for their special day.<br/><br/>
                              The welcome dinner took place under the sumptuous trees of the Mamounia gardens, a large
                              table decorated with hundreds of flowers, lanterns hung from the trees lit with soft light to make
                              the beautiful table decorations shine.<br/><br/>
                              The next day for the wedding, the ceremony took place in the luxurious gardens of the Mamounia,
                              with fragrant orange blossoms in the air. Maryline, radiant in her stunning gown designed by a
                              talented couturier, walked down the aisle adorned with petals and traditional Moroccan lanterns,
                              while Alexandre waited for her with a joyful smile, dressed in an exquisite suit. Their vows were
                              exchanged in front of their loved ones, framed by the timeless beauty of La Mamounia’s iconic
                              arches and fountains.<br/><br/>
                              The celebration continued with a luxurious dinner under the stars in the palace’s enchanting
                              courtyard, where long tables were decorated with touches of gold, flickering candles and lush
                              floral arrangements. Guests enjoyed an exquisite menu that combined Moroccan flavors with
                              international cuisine.<br/><br/>
                              As night fell, the party came alive with dancing under a canopy of twinkling lights in La
                              Mamounia’s club. The energy, laughter and love shared by Maryline, Alexandre and their guests
                              made their wedding at La Mamounia an unforgettable experience filled with magic and romance.<br/><br/>
                              Marrakech is and will always be a great destination for weddings.`,

      // Kasbah Bab Ourika
      "kasbah-bab-ourika-Story": `Victoria and Anthony’s wedding at Kasbah Bab Ourika was a stunning celebration of love,
                                          nestled in the heart of Morocco’s Atlas Mountains. This eco-luxury retreat, perched high above the
                                          lush Ourika Valley, offered breathtaking panoramic views of snow-capped peaks, terraced fields,
                                          and traditional Berber villages—a truly magical setting for their special day.<br/><br/>
                                          The ceremony took place outdoors, with a dramatic mountain backdrop and the soft rustling of
                                          olive trees in the breeze. Victoria, glowing in an elegant gown, walked down a aisle lined with
                                          flowers , while Anthony, dashing in a bespoke suit, awaited her at the end of the aisle decorated
                                          with geant flowers. Their heartfelt vows were exchanged as the sun cast golden hues over the
                                          landscape, creating a romantic and unforgettable moment.<br/><br/>
                                          Following the ceremony, guests enjoyed cocktails on the terrace, sipping artisanal cocktails,
                                          accompanied by locally inspired canapés. The reception unfolded in the kasbah’s courtyard, where
                                          a beautiful tables were decorated with natural linens, candles, and vibrant florals, echoing the
                                          colors of the surrounding landscape. A sumptuous dinner featuring fresh, locally sourced dishes
                                          delighted everyone, with flavors that paid homage to Moroccan culinary traditions.<br/><br/>
                                          As the stars emerged in the clear desert sky, the celebration continued with dancing, live music,
                                          and heartfelt toasts. The afterparty was under the “Alice in Festival Land” theme .<br/><br/>
                                          The intimate, relaxed, and elegant atmosphere of this wedding made Victoria and Anthony’s
                                          wedding at Kasbah Bab Ourika a truly unforgettable experience, filled with love, joy, and the
                                          timeless beauty of Morocco.`,

      // Chateau de Tourreau
      "chateau-de-tourreau-Story": `Lara and Alexandre’s wedding at the Chateau de Tourreau in Provence was a fairytale
                                                      celebration set in one of France’s most enchanting venues.<br/><br/>
                                                      The ceremony took place in the chateau’s exquisite gardens, where lush greenery, manicured
                                                      lawns, and cascading fountains created an atmosphere of romance. Lara, radiant in a flowing
                                                      gown designed by Monique Lhuillier, walked down the aisle lined with white roses and pastel
                                                      florals, while Alexandre, dapper in a tailored suit, awaited her beneath a stunning floral arch. Their
                                                      vows, exchanged under the soft Provençal sun, were heartfelt and moving, surrounded by their
                                                      closest family and friends.<br/><br/>
                                                      After the ceremony, guests enjoyed a sophisticated cocktail hour by the chateau’s sparkling pool,
                                                      sipping on fine French wines and indulging in artisanal canapés inspired by the local cuisine. The
                                                      reception was held in the grand courtyard, where elegant tables adorned with crystal chandeliers,
                                                      lush floral arrangements, and golden accents reflected the chateau’s regal charm.<br/><br/>
                                                      A gourmet multi-course dinner, featuring Provençal delicacies and paired with exquisite wines,
                                                      delighted everyone, while soft live music set the perfect tone for the evening. As the sun set, the
                                                      chateau’s façade was illuminated, and the night came alive with dancing under the stars.<br/><br/>
                                                      Elegant, romantic, and infused with the magic of Provence, their wedding at Chateau de Tourreau
                                                      was truly a day to remember.`,

      // IC Paris Le Grand
      "ic-paris-le-grand-Story": `Julie and Alexandre’s wedding at the InterContinental Paris Le Grand was a dazzling
                                                                  celebration of love, elegance, and Parisian charm. Nestled in the heart of the City of Light, this
                                                                  iconic venue, with its opulent architecture and timeless grandeur, provided the perfect setting for
                                                                  their unforgettable day.<br/><br/>
                                                                  The ceremony was held in the elegant Salon Opéra, a breathtaking ballroom adorned with gilded
                                                                  details, high ceilings, and shimmering chandeliers. Julie, stunning in a sophisticated gown, walked
                                                                  down an aisle lined . Alexandre, impeccably dressed in a classic tuxedo, waited for her with a
                                                                  joyful smile, as their vows resonated beautifully in the romantic and luxurious ambiance.<br/><br/>
                                                                  Following the ceremony, guests were treated to a champagne reception in the hotel’s with a live
                                                                  classical music created a magical Parisian atmosphere. The celebration continued with a
                                                                  sumptuous dinner in the grand ballroom, where tables were adorned with crystal glassware,
                                                                  candlelight, and floral centerpieces reflecting timeless sophistication.<br/><br/>
                                                                  The culinary experience was nothing short of spectacular, with a menu crafted by the hotel’s
                                                                  acclaimed chefs, featuring French haute cuisine paired with exquisite wines. As the evening
                                                                  unfolded, Julie and Alexandre shared their first dance under a canopy of chandeliers, surrounded
                                                                  by their loved ones.<br/><br/>
                                                                  Julie and Alexandre’s wedding at the InterContinental Paris Le Grand was a true celebration of
                                                                  romance and parisien style, forever etched in their memories.`,

      // EL FENN , Marrakech
      "el-fenn-marrakech-Story": `Galilea and Chris’s destination wedding at the enchanting Riad El Fenn in Marrakech was a
                  celebration filled with love, color, and Moroccan charm.<br/><br/>
                  Galilea and Chris came from Chicago to get married in the beautiful Marrakech .<br/><br/>
                  The ceremony took place in the lush central courtyard, where the rich green foliage and intricate
                  Moroccan tilework created a breathtakingly intimate atmosphere. Galilea, radiant in a flowing
                  bohemian-style gown, walked down an aisle adorned with vibrant roses and flickering lanterns.
                  Chris, dressed in a chic linen suit, awaited with a smile of joy. Their vows, shared amidst the
                  warmth of family and friends, were heartfelt and deeply personal.<br/><br/>
                  After the ceremony, guests gathered on the rooftop terrace for a cocktail hour overlooking the
                  iconic Marrakech skyline and the distant Atlas Mountains. They sipped on handcrafted cocktails
                  infused with local flavors and enjoyed a selection of gourmet , blending Moroccan spices with
                  modern flair.<br/><br/>
                  The reception was held in a charming open-air setting, where long table were styled with colorful
                  textiles, brass lanterns, and bold floral centerpieces inspired by the vibrant hues of Marrakech. A
                  feast of traditional Moroccan dishes .<br/><br/>
                  As the evening unfolded, the riad came alive with music and dancing under a canopy of stars.
                  Galilea and Chris’s wedding at Riad El Fenn was a stunning fusion of romance and cultural
                  richness, leaving everyone with memories to treasure for a lifetime.`,

      //seen on
      "seen-on-section1-title": "Where Our Art Shines",
      "seen-on-section1-content": "We’re honored to have our work featured on renowned blogs that celebrate love and artistry. Explore how our photos bring timeless moments to life.",

      "seen-on-section2-title": "Where Our Lens Has Been",
      "seen-on-section2-content": "Our wedding photography has been trusted by prestigious venues and celebrated in stunning locations. Discover where our lens has beautifully captured unforgettable moments.",

      "blog1": `Victoria and Anthony’s wedding at Kasbah Bab
                                        Ourika was a stunning celebration of love, nestled in
                                        the heart of Morocco’s Atlas Mountains. This ecoluxury retreat, perched high above the lush Ourika
                                        Valley, oered breathtaking panoramic views of
                                        snow-capped peaks, terraced elds, and traditional
                                        Berber villages—a truly magical setting for their
                                        special day.`,
      "blog2": `Carolina, a medical student at the time, and Martin, a financial specialist in the energy transition sector who had just returned from working in Colombia, were introduced through a mutual friend in the bustling heart of New York City. Their relationship deepened with fine time together and cultural affinities...`,

      "Read More": "Read More"

    }
  },
  fr: {
    translation: {
      "Home": "Accueil",
      "About": "A Propos",
      "Portfolio": "Portfolio",
      "Contact": "Contact",

      "Photography Portfolio": "Portfolio",
      "View details for": "Afficher les détails pour",

      "CREATE MAGIC": "AVEC VOUS, NOUS CRÉONS DE LA MAGIE",
      "wedding film": "Photographe de mariage et film de mariage cinématographique",
      "Contact us": "Contactez-nous",
      "Contact information": "Informations de contact",
      "contact description1": `Établissons des liens, parlons de vous et de votre journée, et passons un bon moment ensemble.`,

      "contact description2": "Nous vivons et respirons notre beau travail et c'est ce que nous voulons partager avec vous.",

      "contact description3": "Nous essayons toujours de vous donner une réponse de 48 heures. Bien sûr, surtout au mariage. Si vous n'avez rien entendu après 72h s'il vous plaît envoyez-nous un courriel à nouveau!",

      "Address": "Adresse",
      "Phone number": "Numéro de téléphone",
      "Email": "Email",
      "Send us a message": "Envoyez-nous un message",
      "Im Interested In": "Je suis intéressé par",
      "PHOTOGRAPHY": "PHOTOGRAPHY",
      "VIDEOGRAPHY": "VIDEOGRAPHY",
      "BOTH PHOTO VIDEO": "PHOTO + VIDÉO",
      "First name": "Prénom",
      "Last name": "Nom",
      "Phone": "Téléphone",
      "Budget": "Budget",
      "Optional": "Optionnel",
      "Sending": "Envoyer",
      "Submit": "Envoyer",
      "Line and Noah": "Line et Noah sont un couple passionné d'image, de créativité et d'amour.",
      "Together we founded": "Ensemble, nous avons fondé",

      "about1": 'en 2019 et ont versé notre passion pour en faire notre "bébé."',
      "about2": "Nos films et photos sont inspirés par des méthodes et des compositions cinématographiques, et nous nous efforçons de refléter le style unique et la personnalité de chaque mariage à travers notre travail.",
      "about3": "Nous trouvons que la partie la plus gratifiante de notre travail est de capturer ces moments tendres et intemporels et de les transformer en une histoire qui sera racontée et vécue encore et encore.",
      "about4": ", nous sommes fiers de faire de chaque projet un reflet de notre passion et de notre amour. Nous capturons les moments les plus simples de la vie et les rendons magiques.",
      "about5": "Si vous voulez en savoir plus sur nous, écrivez-nous, nous serons heureux de vous parler.",

      // films
      "Venue": "Lieu",
      "Planner": "Organisateur",
      "Story": "Story",


      // Saint Jean Cap-Ferrat
      "saint-jean-cap-ferrat-Planner": "La mariée souhaitait organiser son mariage elle-même, elle a pensé à chaque détail et a été guidée et aidée par l'Équipe événementielle de l'hôtel.",
      "saint-jean-cap-ferrat-Story": `Le mariage d'Anna et Tom au luxueux Hôtel Saint-Jean-Cap-Ferrat a été une célébration de l'amour et de l'élégance à couper le souffle, sur l'époustouflante Côte d'Azur.
                  de l'amour et de l'élégance, sur la magnifique Côte d'Azur. Entouré par la sereine mer Méditerranée, l'Hôtel Saint-Jean-Cap-Ferrat
                  Méditerranée, l'Hôtel Saint-Jean-Cap-Ferrat dégage un charme intemporel, avec ses jardins luxuriants, ses
                  ses jardins luxuriants, ses piscines étincelantes et ses vues à couper le souffle sur la côte. La cérémonie du couple s'est déroulée
                  dans les magnifiques jardins de l'hôtel, ornée de roses blanches et d'un éclairage doux et romantique, créant une atmosphère intime et magique.
                  romantique, créant ainsi une atmosphère intime et magique. Anna était radieuse dans une délicate robe en dentelle
                  tandis que Tom se tenait à ses côtés dans un costume sur mesure, tous deux rayonnants de joie. Après l'échange des
                  Après l'échange des vœux, les invités ont dégusté un cocktail sur la terrasse, avec vue sur la mer scintillante. La soirée s'est
                  La soirée s'est poursuivie par un dîner exquis dans l'élégante salle à manger de l'hôtel.
                  musique douce et des éclats de rire. La nuit s'est achevée par un éblouissant feu d'artifice au-dessus de la Méditerranée, marquant la fin de la fête.
                  feux d'artifice sur la Méditerranée, marquant le début de l'inoubliable voyage d'Anna et de Tom.
                  ensemble.`,

      // La Mamounia
      "la-mamounia-Story": `Maryline et Alexandre se sont mariés dans le cadre légendaire de La Mamounia à Marrakech, une célébration digne d’un conte de fées, mêlant l’esprit de Marrakech à une élégance moderne. Un mariage de destination, niché au cœur de la Ville Rouge, les jardins luxuriants et l’architecture complexe de La Mamounia ont offert un décor inoubliable pour leur journée spéciale.<br/><br/>
                              
      Le dîner de bienvenue a eu lieu sous les arbres somptueux des jardins de La Mamounia, une grande table décorée de centaines de fleurs, avec des lanternes suspendues aux arbres, éclairées d’une lumière douce pour sublimer les magnifiques décorations de table.<br/><br/>
                                    
      Le lendemain, pour le mariage, la cérémonie s’est déroulée dans les jardins luxueux de La Mamounia, avec un parfum de fleurs d’oranger dans l’air. Maryline, rayonnante dans sa magnifique robe conçue par un couturier talentueux, a avancé dans l’allée parsemée de pétales et de lanternes marocaines traditionnelles, tandis qu’Alexandre l’attendait avec un sourire joyeux, vêtu d’un costume raffiné. Leurs vœux ont été échangés devant leurs proches, encadrés par la beauté intemporelle des arches et des fontaines emblématiques de La Mamounia.<br/><br/>
                                    
      La célébration s’est poursuivie avec un dîner luxueux sous les étoiles dans la cour enchantée du palais, où de longues tables étaient décorées de touches dorées, de bougies scintillantes et de somptueux arrangements floraux. Les invités ont dégusté un menu exquis mêlant saveurs marocaines et cuisine internationale.<br/><br/>
                                    
      À la tombée de la nuit, la fête s’est animée avec des danses sous un ciel illuminé de lumières scintillantes dans le club de La Mamounia. L’énergie, les rires et l’amour partagés par Maryline, Alexandre et leurs invités ont fait de leur mariage à La Mamounia une expérience inoubliable, empreinte de magie et de romantisme.<br/><br/>
                                    
      Marrakech est et restera une destination de choix pour les mariages.
      `,

      // Kasbah Bab Ourika
      "kasbah-bab-ourika-Story": `Le mariage de Victoria et Anthony au Kasbah Bab Ourika a été une magnifique célébration d'amour, nichée au cœur des montagnes de l'Atlas au Maroc. Ce refuge éco-luxueux, perché au-dessus de la luxuriante vallée de l'Ourika, offrait des vues panoramiques à couper le souffle sur les sommets enneigés, les champs en terrasses et les villages berbères traditionnels—un cadre véritablement magique pour leur journée spéciale.<br/><br/>
                                          
      La cérémonie a eu lieu en plein air, avec en toile de fond les montagnes majestueuses et le doux bruissement des oliviers dans la brise. Victoria, resplendissante dans une robe élégante, a avancé sur une allée bordée de fleurs, tandis qu’Anthony, charmant dans un costume sur mesure, l’attendait au bout de l’allée décorée de fleurs géantes. Leurs vœux émouvants ont été échangés alors que le soleil teintait le paysage de nuances dorées, créant un moment romantique et inoubliable.<br/><br/>
                                                
      Après la cérémonie, les invités ont profité d’un cocktail sur la terrasse, savourant des cocktails artisanaux accompagnés de canapés inspirés de la cuisine locale. La réception s’est déroulée dans la cour du kasbah, où de belles tables étaient décorées de nappes naturelles, de bougies et de fleurs vibrantes, reflétant les couleurs du paysage environnant. Un somptueux dîner mettant en vedette des plats frais et locaux a ravi tous les convives, avec des saveurs rendant hommage aux traditions culinaires marocaines.<br/><br/>
                                                
      Alors que les étoiles apparaissaient dans le ciel désertique clair, la fête a continué avec des danses, de la musique live et des toasts sincères. L’afterparty s’est déroulée sous le thème “Alice in Festival Land”.<br/><br/>
                                                
      L’atmosphère intime, détendue et élégante de ce mariage a fait du mariage de Victoria et Anthony au Kasbah Bab Ourika une expérience véritablement inoubliable, remplie d’amour, de joie et de la beauté intemporelle du Maroc.
      `,

      // Chateau de Tourreau
      "chateau-de-tourreau-Story": `Le mariage de Lara et Alexandre au Chateau de Tourreau en Provence a été une célébration digne d’un conte de fées, dans l’un des lieux les plus enchanteurs de France.<br/><br/>

      La cérémonie a eu lieu dans les jardins exquis du chateau, où la verdure luxuriante, les pelouses impeccablement entretenues et les fontaines en cascade ont créé une atmosphère romantique. Lara, resplendissante dans une robe fluide signée Monique Lhuillier, a avancé sur une allée bordée de roses blanches et de fleurs pastel, tandis qu’Alexandre, élégant dans un costume sur mesure, l’attendait sous une magnifique arche florale. Leurs vœux, échangés sous le doux soleil provençal, étaient sincères et émouvants, entourés de leurs familles et amis les plus proches.<br/><br/>

      Après la cérémonie, les invités ont profité d’une heure de cocktail sophistiquée au bord de la piscine scintillante du chateau, savourant de grands vins français et des canapés artisanaux inspirés de la cuisine locale. La réception s’est tenue dans la grande cour, où des tables élégantes ornées de lustres en cristal, de somptueuses compositions florales et de touches dorées reflétaient le charme royal du chateau.<br/><br/>

      Un dîner gastronomique en plusieurs services, mettant en vedette des délices provençaux et accompagné de vins exquis, a ravi tous les convives, tandis qu’une musique live douce donnait le ton parfait pour la soirée. Au coucher du soleil, la façade du chateau s’est illuminée, et la nuit a pris vie avec des danses sous les étoiles.<br/><br/>

      Élégant, romantique et imprégné de la magie de la Provence, leur mariage au Chateau de Tourreau a été une journée véritablement inoubliable.
      `,

      // IC Paris Le Grand
      "ic-paris-le-grand-Story": `Le mariage de Julie et Alexandre à l’InterContinental Paris Le Grand a été une célébration éblouissante d’amour, d’élégance et de charme parisien. Niché au cœur de la Ville Lumière, ce lieu emblématique, avec son architecture opulente et sa grandeur intemporelle, a offert le cadre parfait pour leur journée inoubliable.<br/><br/>

      La cérémonie s’est tenue dans le somptueux Salon Opéra, une salle de bal à couper le souffle ornée de détails dorés, de hauts plafonds et de lustres scintillants. Julie, magnifique dans une robe sophistiquée, a avancé dans une allée bordée de fleurs, tandis qu’Alexandre, impeccablement vêtu d’un smoking classique, l’attendait avec un sourire radieux. Leurs vœux, échangés dans cette ambiance romantique et luxueuse, ont résonné avec émotion.<br/><br/>

      Après la cérémonie, les invités ont profité d’une réception au champagne dans l’hôtel, accompagnée d’une musique classique jouée en live, créant une atmosphère magique et typiquement parisienne. La célébration s’est poursuivie par un somptueux dîner dans la grande salle de bal, où les tables étaient décorées de verrerie en cristal, de bougies et de centres de table floraux reflétant une sophistication intemporelle.<br/><br/>

      L’expérience culinaire a été tout simplement spectaculaire, avec un menu élaboré par les chefs renommés de l’hôtel, mettant en avant la haute cuisine française accompagnée de vins exquis. Au fil de la soirée, Julie et Alexandre ont partagé leur première danse sous un ciel de lustres, entourés de leurs proches.<br/><br/>

      Le mariage de Julie et Alexandre à l’InterContinental Paris Le Grand a été une véritable célébration de romance et de style parisien, gravée à jamais dans leurs mémoires.
      `,

      // EL FENN , Marrakech
      "el-fenn-marrakech-Story": `Le mariage de Galilea et Chris au Riad El Fenn à Marrakech a été une célébration enchantée, remplie d’amour, de couleurs et de charme marocain.<br/><br/>

      Galilea et Chris sont venus de Chicago pour se marier dans la magnifique ville de Marrakech.<br/><br/>

      La cérémonie s’est tenue dans la cour centrale luxuriante, où la verdure abondante et les carreaux marocains complexes ont créé une atmosphère intime et à couper le souffle. Galilea, resplendissante dans une robe bohème fluide, a avancé dans une allée ornée de roses vibrantes et de lanternes scintillantes. Chris, vêtu d’un élégant costume en lin, l’attendait avec un sourire joyeux. Leurs vœux, échangés au milieu de leurs familles et amis, étaient sincères et profondément personnels.<br/><br/>

      Après la cérémonie, les invités se sont rassemblés sur la terrasse du toit pour une heure de cocktail offrant une vue imprenable sur la skyline emblématique de Marrakech et les montagnes de l’Atlas au loin. Ils ont savouré des cocktails artisanaux infusés de saveurs locales et une sélection de délices gastronomiques mêlant épices marocaines et modernité.<br/><br/>

      La réception s’est déroulée dans un cadre charmant en plein air, où de longues tables étaient décorées de textiles colorés, de lanternes en laiton et de centres de table floraux audacieux inspirés des teintes vibrantes de Marrakech. Un festin de plats traditionnels marocains a ravi tous les convives.<br/><br/>

      Au fil de la soirée, le riad s’est animé avec de la musique et des danses sous un ciel étoilé. Le mariage de Galilea et Chris au Riad El Fenn a été une fusion éblouissante de romance et de richesse culturelle, laissant à tous des souvenirs à chérir pour toujours.
      `,

      //seen on
      "seen-on-section1-title": "Où notre art brille",
      "seen-on-section1-content": "Nous sommes honorés que notre travail soit présenté sur des blogs renommés qui célèbrent l'amour et l'art. Découvrez comment nos photos donnent vie à des moments intemporels.",

      "seen-on-section2-title": "Où est passé notre objectif",
      "seen-on-section2-content": "Nos photographies de mariage ont été confiées à des lieux prestigieux et ont été célébrées dans des endroits étonnants. Découvrez les endroits où notre objectif a magnifiquement capturé des moments inoubliables.",

      "blog1": `Le mariage de Victoria et Anthony à la Kasbah Bab
                                        Ourika a été une magnifique célébration de l'amour, nichée au cœur de l'Atlas marocain.
                                        au cœur de l'Atlas marocain. Ce lieu de retraite écoluxe, perché au-dessus de la luxuriante vallée de l'Ourika, offre des vues panoramiques à couper le souffle sur les montagnes de l'Atlas.
                                        de l'Ourika, offre des vues panoramiques à couper le souffle sur des
                                        des pics enneigés, des terrasses et des villages berbères traditionnels.
                                        villages berbères traditionnels - un cadre vraiment magique pour
                                        pour leur journée spéciale...`,
      "blog2": `Carolina, alors étudiante en médecine, et Martin, spécialiste financier dans le secteur de la transition énergétique qui venait de rentrer de Colombie, se sont rencontrés par l'intermédiaire d'un ami commun dans le cœur animé de New York. Leur relation s'est approfondie au fil du temps et des affinités culturelles...`,

      "Read More": "Lire plus"

    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("lng") || 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;