import React from 'react'
import '../wedding.css'
import { Link } from 'react-router-dom';
import OptimizedWeddingImage from '../../OptimizedWeddingImage';
import { weddingImages } from '../../../photos';

export default function GalleryList() {


    return (
        <div className="mx-auto max-w-2xl py-16 px-4 lg:max-w-7xl lg:gap-x-8 lg:px-8 mt-20">
            <h1 className="text-4xl mb-10 text-center underline underline-offset-8">Photos</h1>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 xl:w-1/2 w-full lg:w-2/3 m-auto">
                {weddingImages.map((image, index) => (
                    <div key={index}>
                        <Link to={`./${image.id}/${image.number}`}>
                            <OptimizedWeddingImage
                                src={image.image}
                                alt={image.alt}
                                title={image.title}
                            />
                            <div className='text-lg py-3 text-center mb-4'>{image.title}</div>
                        </Link>
                    </div>
                ))}
            </div>

        </div>
    )
}
