import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'

export default function About() {
  const { t } = useTranslation();
  return (
    <>
      <div>

        <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 mt-20">
          {/* Product details */}
          <div className="lg:max-w-lg lg:self-end">
            <div>
              <h1 className="text-4xl font-noirblanc mb-10"> {t('Line and Noah')}</h1>
              <p className='text-lg font-light'>
                {t('Together we founded')} <span className='font-noirblanc'>VIEW</span> {t('about1')} <br />
                {t('about2')} <br />
                {t('about3')}
                <br /><br />
                At <span className='font-noirblanc'>VIEW</span>
                {t('about4')}<br /><br />
                {t('about5')}
              </p>

              <div className='text-center mt-7'>
                <NavLink to="/contact" className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-[#59200B] px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-[#59200B]/90 focus:outline-none">{t('Contact us')}</NavLink>
              </div>
            </div>
          </div>

          {/* Product image */}
          <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
              <div><img src="https://res.cloudinary.com/dt6rowwfl/image/upload/v1734725261/alia-noah.jpg" alt="" className="h-full w-full object-cover object-center" /></div>
              {/* <div><img src="/images/noah.jpg" alt="" className="h-full w-full object-cover object-center" /></div> */}
            </div>
          </div>

        </div>


      </div>
    </>
  )
}
