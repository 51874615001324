import React, { createContext, useState } from 'react';

// Create a Context
export const ThemeContext = createContext();

// Create a Provider Component
export const ThemeProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <ThemeContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
        </ThemeContext.Provider>
    );
};
