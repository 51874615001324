import React, { useState } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { auto, fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';


const OptimizedWeddingImage = ({
    src,
    alt,
    title,
    placeholder = true
}) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const cld = new Cloudinary({ cloud: { cloudName: 'dt6rowwfl' } });

    const img = cld.image(src).format("auto").quality("auto").resize(fill("auto", 1920));

    return (
        <div className="relative overflow-hidden">
            {/* Placeholder/Low-quality image for initial load */}
            {placeholder && !isLoaded && (
                <div className="absolute inset-0 bg-[#bd8d7b] animate-pulse flex items-center justify-center rounded-lg">
                    <svg className="w-10 h-10 text-[#FBF7ED] dark:text-[#FBF7ED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                </div>
            )}

            {/* <img
                src={src}
                alt={alt}
                title={title}
                loading="lazy"
                className={`
                w-full 
                h-auto 
                object-cover 
                transition-opacity 
                duration-500 
                rounded-lg
                min-h-[440px]
                ${!isLoaded ? 'opacity-0' : 'opacity-100'}
                `}
                onLoad={() => setIsLoaded(true)}
            /> */}

            <AdvancedImage
                cldImg={img}
                onLoad={() => setIsLoaded(true)}
                className={`duration-500 transition-opacity w-full rounded-lg ${placeholder && !isLoaded ? "min-h-[520px]" : "h-auto"}`} alt={alt} title={title}
            />
        </div>
    );
};

export default OptimizedWeddingImage;