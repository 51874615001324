export const galleries = {
  "Chateau-SMDT": {
    title: "Chateau SMDT",
    story: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",

    gallery: {
      
    }
  }
}


export const weddingImages = [
  {
      id: "chateau-SMDT-wedding-photography",
      title: "Chateau SMDT",
      image: "chateau-SMDT-wedding-photography-2",
      alt: "Wedding photography at Chateau SMDT - Perfect venue for an elegant wedding",
      number: 24
  },
  {
      id: "el-feen-marrakech-wedding-photography",
      title: "EL FEEN , Marrakech",
      image: "el-feen-marrakech-wedding-photography-25",
      alt: "EL FEEN Marrakech wedding photography - Capture your special day with stunning Moroccan landscapes",
      number: 31
  },
  {
      id: "luxury-wedding-la-mamounia-morocco",
      title: "LA MAMOUNIA , Marrakech",
      image: "luxury-wedding-la-mamounia-morocco-4",
      alt: "Luxury wedding photography at LA MAMOUNIA Marrakech - Ideal for a royal wedding setting",
      number: 16
  },
  {
      id: "outdoor-ceremony-chateau-de-meridon",
      title: "Chateau de Meridon , PARIS",
      image: "outdoor-ceremony-chateau-de-meridon-14",
      alt: "Romantic wedding photography at Chateau de Meridon, Paris - Timeless elegance for your big day",
      number: 22
  },
  {
      id: "intercontinental-paris-le-grand-wedding-photography",
      title: "Intercontinental Paris Le Grand",
      image: "intercontinental-paris-le-grand-wedding-photography-1",
      alt: "Wedding photography at Intercontinental Paris Le Grand - Iconic Parisian luxury for your wedding",
      number: 16
  },
  {
      id: "luxury-wedding-kasbah-bab-ourika",
      title: "Kasbah bab ourika , Maroc",
      image: "luxury-wedding-kasbah-bab-ourika-14",
      alt: "Wedding photography at Kasbah Bab Ourika, Morocco - Beautiful natural setting for a unique wedding",
      number: 23
  },
  {
      id: "outdoor-wedding-provence-moulin-vaucroze",
      title: "Moulin de Vaucruze , Provence",
      image: "outdoor-wedding-provence-moulin-vaucroze-16",
      alt: "Provence wedding photography at Moulin de Vaucruze - Rustic charm for your special day",
      number: 25
  }
]

export const photos = {
  "IC-PARIS": [
    {
      src: "/images/gallery/IC-PARIS/0.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/1.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/2.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/3.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/4.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/5.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/6.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/7.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/8.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/9.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/10.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/11.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/12.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/13.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/14.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/15.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/16.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/17.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/18.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/19.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/20.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/21.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/22.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/23.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/24.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/25.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/26.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/27.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/28.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/29.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/30.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/31.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/32.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/33.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/34.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/35.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/36.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/37.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/38.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/39.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/40.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/41.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/42.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/43.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/44.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/45.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/46.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/47.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/48.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/49.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/50.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/51.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/52.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/53.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/54.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/55.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/56.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/57.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/58.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/59.jpg",
    },
    {
      src: "/images/gallery/IC-PARIS/60.jpg",
    }
  ],
  "LYON": [
    {
      src: "/images/gallery/LYON/0.jpg",
    },
    {
      src: "/images/gallery/LYON/1.jpg",
    },
    {
      src: "/images/gallery/LYON/2.jpg",
    },
    {
      src: "/images/gallery/LYON/3.jpg",
    },
    {
      src: "/images/gallery/LYON/4.jpg",
    },
    {
      src: "/images/gallery/LYON/5.jpg",
    },
    {
      src: "/images/gallery/LYON/6.jpg",
    },
    {
      src: "/images/gallery/LYON/7.jpg",
    },
    {
      src: "/images/gallery/LYON/8.jpg",
    },
    {
      src: "/images/gallery/LYON/9.jpg",
    },
    {
      src: "/images/gallery/LYON/10.jpg",
    },
    {
      src: "/images/gallery/LYON/11.jpg",
    },
    {
      src: "/images/gallery/LYON/12.jpg",
    },
    {
      src: "/images/gallery/LYON/13.jpg",
    },
    {
      src: "/images/gallery/LYON/14.jpg",
    },
    {
      src: "/images/gallery/LYON/15.jpg",
    },
    {
      src: "/images/gallery/LYON/16.jpg",
    },
    {
      src: "/images/gallery/LYON/17.jpg",
    },
    {
      src: "/images/gallery/LYON/18.jpg",
    },
    {
      src: "/images/gallery/LYON/19.jpg",
    },
    {
      src: "/images/gallery/LYON/20.jpg",
    },
    {
      src: "/images/gallery/LYON/21.jpg",
    },
    {
      src: "/images/gallery/LYON/22.jpg",
    },
    {
      src: "/images/gallery/LYON/23.jpg",
    },
    {
      src: "/images/gallery/LYON/24.jpg",
    },
    {
      src: "/images/gallery/LYON/25.jpg",
    },
    {
      src: "/images/gallery/LYON/26.jpg",
    },
    {
      src: "/images/gallery/LYON/27.jpg",
    },
    {
      src: "/images/gallery/LYON/28.jpg",
    },
    {
      src: "/images/gallery/LYON/29.jpg",
    },
    {
      src: "/images/gallery/LYON/30.jpg",
    },
    {
      src: "/images/gallery/LYON/31.jpg",
    },
    {
      src: "/images/gallery/LYON/32.jpg",
    },
    {
      src: "/images/gallery/LYON/33.jpg",
    },
    {
      src: "/images/gallery/LYON/34.jpg",
    },
    {
      src: "/images/gallery/LYON/35.jpg",
    },
    {
      src: "/images/gallery/LYON/36.jpg",
    },
    {
      src: "/images/gallery/LYON/37.jpg",
    },
    {
      src: "/images/gallery/LYON/38.jpg",
    },
    {
      src: "/images/gallery/LYON/39.jpg",
    },
    {
      src: "/images/gallery/LYON/40.jpg",
    },
    {
      src: "/images/gallery/LYON/41.jpg",
    },
    {
      src: "/images/gallery/LYON/42.jpg",
    },
    {
      src: "/images/gallery/LYON/43.jpg",
    },
    {
      src: "/images/gallery/LYON/44.jpg",
    },
    {
      src: "/images/gallery/LYON/45.jpg",
    },
    {
      src: "/images/gallery/LYON/46.jpg",
    },
    {
      src: "/images/gallery/LYON/47.jpg",
    },
    {
      src: "/images/gallery/LYON/48.jpg",
    },
    {
      src: "/images/gallery/LYON/49.jpg",
    },
    {
      src: "/images/gallery/LYON/50.jpg",
    },
    {
      src: "/images/gallery/LYON/51.jpg",
    },
    {
      src: "/images/gallery/LYON/52.jpg",
    },
    {
      src: "/images/gallery/LYON/53.jpg",
    },
    {
      src: "/images/gallery/LYON/54.jpg",
    },
    {
      src: "/images/gallery/LYON/55.jpg",
    },
    {
      src: "/images/gallery/LYON/56.jpg",
    },
    {
      src: "/images/gallery/LYON/57.jpg",
    },
    {
      src: "/images/gallery/LYON/58.jpg",
    },
    {
      src: "/images/gallery/LYON/59.jpg",
    },
    {
      src: "/images/gallery/LYON/60.jpg",
    },
    {
      src: "/images/gallery/LYON/61.jpg",
    },
    {
      src: "/images/gallery/LYON/62.jpg",
    },
    {
      src: "/images/gallery/LYON/63.jpg",
    },
    {
      src: "/images/gallery/LYON/64.jpg",
    },
    {
      src: "/images/gallery/LYON/65.jpg",
    },
    {
      src: "/images/gallery/LYON/66.jpg",
    },
    {
      src: "/images/gallery/LYON/67.jpg",
    },
    {
      src: "/images/gallery/LYON/68.jpg",
    },
    {
      src: "/images/gallery/LYON/69.jpg",
    },
    {
      src: "/images/gallery/LYON/70.jpg",
    },
    {
      src: "/images/gallery/LYON/71.jpg",
    },
    {
      src: "/images/gallery/LYON/72.jpg",
    },
    {
      src: "/images/gallery/LYON/73.jpg",
    },
    {
      src: "/images/gallery/LYON/74.jpg",
    },
    {
      src: "/images/gallery/LYON/75.jpg",
    },
    {
      src: "/images/gallery/LYON/76.jpg",
    },
    {
      src: "/images/gallery/LYON/77.jpg",
    },
    {
      src: "/images/gallery/LYON/78.jpg",
    },
    {
      src: "/images/gallery/LYON/79.jpg",
    },
    {
      src: "/images/gallery/LYON/80.jpg",
    },
    {
      src: "/images/gallery/LYON/81.jpg",
    },
    {
      src: "/images/gallery/LYON/82.jpg",
    },
    {
      src: "/images/gallery/LYON/83.jpg",
    },
    {
      src: "/images/gallery/LYON/84.jpg",
    },
    {
      src: "/images/gallery/LYON/85.jpg",
    },
    {
      src: "/images/gallery/LYON/86.jpg",
    }
  ],
  "LAURA": [
    {
      src: "/images/gallery/LAURA/0.jpg",
    },
    {
      src: "/images/gallery/LAURA/1.jpg",
    },
    {
      src: "/images/gallery/LAURA/2.jpg",
    },
    {
      src: "/images/gallery/LAURA/3.jpg",
    },
    {
      src: "/images/gallery/LAURA/4.jpg",
    },
    {
      src: "/images/gallery/LAURA/5.jpg",
    },
    {
      src: "/images/gallery/LAURA/6.jpg",
    },
    {
      src: "/images/gallery/LAURA/7.jpg",
    },
    {
      src: "/images/gallery/LAURA/8.jpg",
    },
    {
      src: "/images/gallery/LAURA/9.jpg",
    },
    {
      src: "/images/gallery/LAURA/10.jpg",
    },
    {
      src: "/images/gallery/LAURA/11.jpg",
    },
    {
      src: "/images/gallery/LAURA/12.jpg",
    },
    {
      src: "/images/gallery/LAURA/13.jpg",
    },
    {
      src: "/images/gallery/LAURA/14.jpg",
    },
    {
      src: "/images/gallery/LAURA/15.jpg",
    },
    {
      src: "/images/gallery/LAURA/16.jpg",
    },
    {
      src: "/images/gallery/LAURA/17.jpg",
    },
    {
      src: "/images/gallery/LAURA/18.jpg",
    },
    {
      src: "/images/gallery/LAURA/19.jpg",
    },
    {
      src: "/images/gallery/LAURA/20.jpg",
    },
    {
      src: "/images/gallery/LAURA/21.jpg",
    },
    {
      src: "/images/gallery/LAURA/22.jpg",
    },
    {
      src: "/images/gallery/LAURA/23.jpg",
    },
    {
      src: "/images/gallery/LAURA/24.jpg",
    },
    {
      src: "/images/gallery/LAURA/25.jpg",
    },
    {
      src: "/images/gallery/LAURA/26.jpg",
    },
    {
      src: "/images/gallery/LAURA/27.jpg",
    },
    {
      src: "/images/gallery/LAURA/28.jpg",
    },
    {
      src: "/images/gallery/LAURA/29.jpg",
    },
    {
      src: "/images/gallery/LAURA/30.jpg",
    },
    {
      src: "/images/gallery/LAURA/31.jpg",
    },
  ],
  "CHATEAU-ROBERNIER": [
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/0.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/1.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/2.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/3.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/4.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/5.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/6.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/7.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/8.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/9.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/10.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/11.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/12.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/13.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/14.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/15.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/16.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/17.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/18.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/19.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/20.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/21.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/22.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/23.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/24.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/25.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/26.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/27.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/28.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/29.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/30.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/31.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/32.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/33.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/34.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/35.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/36.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/37.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/38.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/39.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/40.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/41.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/42.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/43.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/44.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/45.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/46.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/47.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/48.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/49.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/50.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/51.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/52.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/53.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/54.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/55.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/56.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/57.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-ROBERNIER/58.jpg",
    },
  ],
  "MARRAKECH": [
    {
      src: "/images/gallery/MARRAKECH/0.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/1.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/2.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/3.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/4.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/5.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/6.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/7.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/8.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/9.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/10.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/11.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/12.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/13.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/14.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/15.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/16.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/17.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/18.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/19.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/20.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/21.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/22.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/23.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/24.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/25.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/26.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/27.jpg",
    },
    {
      src: "/images/gallery/MARRAKECH/28.jpg",
    },
  ],
  "BOHEME": [
    {
      src: "/images/gallery/BOHEME/0.jpg",
    },
    {
      src: "/images/gallery/BOHEME/1.jpg",
    },
    {
      src: "/images/gallery/BOHEME/2.jpg",
    },
    {
      src: "/images/gallery/BOHEME/3.jpg",
    },
    {
      src: "/images/gallery/BOHEME/4.jpg",
    },
    {
      src: "/images/gallery/BOHEME/5.jpg",
    },
    {
      src: "/images/gallery/BOHEME/6.jpg",
    },
    {
      src: "/images/gallery/BOHEME/7.jpg",
    },
    {
      src: "/images/gallery/BOHEME/8.jpg",
    },
    {
      src: "/images/gallery/BOHEME/9.jpg",
    },
    {
      src: "/images/gallery/BOHEME/10.jpg",
    },
    {
      src: "/images/gallery/BOHEME/11.jpg",
    },
    {
      src: "/images/gallery/BOHEME/12.jpg",
    },
    {
      src: "/images/gallery/BOHEME/13.jpg",
    },
    {
      src: "/images/gallery/BOHEME/14.jpg",
    },
    {
      src: "/images/gallery/BOHEME/15.jpg",
    },
    {
      src: "/images/gallery/BOHEME/16.jpg",
    },
    {
      src: "/images/gallery/BOHEME/17.jpg",
    },
    {
      src: "/images/gallery/BOHEME/18.jpg",
    },
    {
      src: "/images/gallery/BOHEME/19.jpg",
    },
    {
      src: "/images/gallery/BOHEME/20.jpg",
    },
    {
      src: "/images/gallery/BOHEME/21.jpg",
    },
    {
      src: "/images/gallery/BOHEME/22.jpg",
    },
    {
      src: "/images/gallery/BOHEME/23.jpg",
    },
    {
      src: "/images/gallery/BOHEME/24.jpg",
    },
    {
      src: "/images/gallery/BOHEME/25.jpg",
    },
    {
      src: "/images/gallery/BOHEME/26.jpg",
    },
    {
      src: "/images/gallery/BOHEME/27.jpg",
    },
  ],
  "CAMARGUE": [
    {
      src: "/images/gallery/CAMARGUE/0.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/1.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/2.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/3.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/4.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/5.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/6.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/7.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/8.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/9.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/10.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/11.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/12.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/13.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/14.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/15.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/16.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/17.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/18.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/19.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/20.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/21.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/22.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/23.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/24.jpg",
    },
    {
      src: "/images/gallery/CAMARGUE/25.jpg",
    },
  ],
  "CAP-FERRET": [
    {
      src: "/images/gallery/CAP-FERRET/0.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/1.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/2.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/3.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/4.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/5.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/6.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/7.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/8.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/9.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/10.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/11.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/12.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/13.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/14.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/15.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/16.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/17.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/18.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/19.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/20.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/21.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/22.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/23.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/24.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/25.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/26.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/27.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/28.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/29.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/30.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/31.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/32.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/33.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/34.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/35.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/36.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/37.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/38.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/39.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/40.jpg",
    },
    {
      src: "/images/gallery/CAP-FERRET/41.jpg",
    },
  ],
  "CHATEAU-DE-LA-BARONNIE": [
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/0.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/1.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/2.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/3.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/4.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/5.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/6.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/7.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/8.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/9.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/10.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/11.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/12.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/13.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/14.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/15.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/16.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/17.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/18.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/19.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/20.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/21.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/22.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/23.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-DE-LA-BARONNIE/24.jpg",
    },
  ],
  "CHATEAU-LAFITTE": [
    {
      src: "/images/gallery/CHATEAU-LAFITTE/0.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/1.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/2.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/3.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/4.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/5.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/6.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/7.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/8.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/9.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/10.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/11.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/12.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/13.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/14.jpg",
    },
    {
      src: "/images/gallery/CHATEAU-LAFITTE/15.jpg",
    },
  ],
  "LOVE": [
    {
      src: "/images/gallery/LOVE/0.jpg",
    },
    {
      src: "/images/gallery/LOVE/1.jpg",
    },
    {
      src: "/images/gallery/LOVE/2.jpg",
    },
    {
      src: "/images/gallery/LOVE/3.jpg",
    },
    {
      src: "/images/gallery/LOVE/4.jpg",
    },
    {
      src: "/images/gallery/LOVE/5.jpg",
    },
    {
      src: "/images/gallery/LOVE/6.jpg",
    },
    {
      src: "/images/gallery/LOVE/7.jpg",
    },
    {
      src: "/images/gallery/LOVE/8.jpg",
    },
    {
      src: "/images/gallery/LOVE/9.jpg",
    },
    {
      src: "/images/gallery/LOVE/10.jpg",
    },
    {
      src: "/images/gallery/LOVE/11.jpg",
    },
    {
      src: "/images/gallery/LOVE/12.jpg",
    },
    {
      src: "/images/gallery/LOVE/13.jpg",
    },
  ],
  "CATALONA": [
    {
      src: "/images/gallery/CATALONA/0.jpg",
    },
    {
      src: "/images/gallery/CATALONA/1.jpg",
    },
    {
      src: "/images/gallery/CATALONA/2.jpg",
    },
    {
      src: "/images/gallery/CATALONA/3.jpg",
    },
    {
      src: "/images/gallery/CATALONA/4.jpg",
    },
    {
      src: "/images/gallery/CATALONA/5.jpg",
    },
    {
      src: "/images/gallery/CATALONA/6.jpg",
    },
    {
      src: "/images/gallery/CATALONA/7.jpg",
    },
    {
      src: "/images/gallery/CATALONA/8.jpg",
    },
    {
      src: "/images/gallery/CATALONA/9.jpg",
    },
    {
      src: "/images/gallery/CATALONA/10.jpg",
    },
    {
      src: "/images/gallery/CATALONA/11.jpg",
    },
    {
      src: "/images/gallery/CATALONA/12.jpg",
    },
    {
      src: "/images/gallery/CATALONA/13.jpg",
    },
    {
      src: "/images/gallery/CATALONA/14.jpg",
    },
    {
      src: "/images/gallery/CATALONA/15.jpg",
    },
    {
      src: "/images/gallery/CATALONA/16.jpg",
    },
    {
      src: "/images/gallery/CATALONA/17.jpg",
    },
    {
      src: "/images/gallery/CATALONA/18.jpg",
    },
    {
      src: "/images/gallery/CATALONA/19.jpg",
    },
    {
      src: "/images/gallery/CATALONA/20.jpg",
    },
    {
      src: "/images/gallery/CATALONA/21.jpg",
    },
    {
      src: "/images/gallery/CATALONA/22.jpg",
    },
    {
      src: "/images/gallery/CATALONA/23.jpg",
    },
    {
      src: "/images/gallery/CATALONA/24.jpg",
    },
    {
      src: "/images/gallery/CATALONA/25.jpg",
    },
  ],
};
