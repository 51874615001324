// VideoOnHover.jsx
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const VideoOnHover = ({ id, videoUrl, fallbackImageUrl, onActiveChange }) => {
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsIOS(iOS);
  }, []);

  useEffect(() => {
    const checkParentAndPlay = () => {
      const parent = videoRef.current?.parentElement.parentElement;
      if (parent?.classList.contains('swiper-slide-active')) {
        handlePlay();
        onActiveChange?.(id);
      } else {
        handlePause();
      }
    };

    checkParentAndPlay();

    const observer = new MutationObserver(checkParentAndPlay);
    const parent = videoRef.current?.parentElement.parentElement;

    if (parent) {
      observer.observe(parent, {
        attributes: true,
        attributeFilter: ['class']
      });
    }

    return () => observer.disconnect();
  }, [id, onActiveChange]);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.log("Auto-play was prevented:", error);
        });
      }
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  //   if (!isIOS) {
  //     handlePlay();
  //   }
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  //   if (!isIOS) {
  //     handlePause();
  //   }
  // };

  // const handleTouchStart = () => {
  //   if (isIOS) {
  //     if (isPlaying) {
  //       handlePause();
  //     } else {
  //       handlePlay();
  //     }
  //   }
  // };

  const handleRedirect = () => {
    const parent = videoRef.current?.parentElement.parentElement;
    if (parent && parent.classList.contains('swiper-slide-active')) {
      navigate(`/wedding/film/${id}`);
    }
  };

  return (
    <div
      className={`video-container ${isPlaying ? 'playing' : ''}`}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      // onTouchStart={handleTouchStart}
      onClick={handleRedirect}
    >
      <video
        ref={videoRef}
        muted
        playsInline
        preload="metadata"
        poster={fallbackImageUrl}
      >
        <source src={videoUrl} type="video/mp4" />
        <img
          src={fallbackImageUrl}
          alt="Video not supported"
          style={{ display: 'block', margin: '0 auto' }}
        />
      </video>
    </div>
  );
};

export default VideoOnHover;