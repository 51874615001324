import "./App.css";
import Home from "./Components/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Portfolio from "./Components/Portfolio";
import Header2 from "./Components/Header2";
import Film from "./Components/wedding/film/Film";
import FilmList from "./Components/wedding/film/";
import { useContext, useEffect } from "react";
import { ThemeContext } from "./ThemeContext";
import SeenOn from "./Components/SeenOn/SeenOn";
import GalleryList from "./Components/wedding/gallery/";
import Gallery from "./Components/wedding/gallery/gallery";

function App() {

  const { isLoading, setIsLoading } = useContext(ThemeContext);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <Router>
      <Header2 />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/" element={<Portfolio />}></Route>
        <Route path="/wedding/photo" element={<GalleryList />}></Route>
        <Route path="/wedding/photo/:id/:number" element={<Gallery />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/wedding/film" element={<FilmList />}></Route>
        <Route path="/wedding/film/:id" element={<Film />}></Route>
        <Route path="/seen-on" element={<SeenOn />}></Route>
      </Routes>
      {/* <div className="fixed bottom-[40px] right-[40px] bg-black/50 rounded-[20px] shake-horizontal p-2" >
          <a href="https://www.instagram.com/viewfilms.w/" target="_blank" rel="noreferrer"><img src="/images/SVG/insta.svg" alt="instagram" width={60}/></a>
        </div> */}
      {/* <div className=""></div> */}

      <div className={`fixed top-0 left-0 rounded-[7px] bg-white z-[9999] w-screen h-screen p-[10%] ${isLoading ? '' : 'hidden'
        }`}>

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2924.000000 870.000000" preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,870.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M690 7980 l0 -70 438 0 438 0 1370 -3597 1371 -3598 256 -3 255 -2
        106 267 c58 148 181 461 274 697 l169 428 -43 117 c-23 64 -520 1366 -1103
        2894 -584 1528 -1061 2782 -1061 2787 0 6 183 10 495 10 l495 0 0 70 0 70
        -1730 0 -1730 0 0 -70z" className="svg-elem-1"></path>
            <path d="M6540 7980 l0 -70 985 0 985 0 0 70 0 70 -985 0 -985 0 0 -70z" className="svg-elem-2"></path>
            <path d="M8720 7980 l0 -70 475 0 475 0 0 -3530 0 -3530 -475 0 -475 0 0 -70
        0 -70 1700 0 1700 0 0 70 0 70 -475 0 -475 0 0 3530 0 3530 475 0 475 0 0 70
        0 70 -1700 0 -1700 0 0 -70z" className="svg-elem-3"></path>
            <path d="M13300 7980 l0 -70 818 0 c781 0 1026 -7 1171 -31 366 -61 686 -220
        948 -472 132 -128 208 -219 309 -372 125 -191 229 -404 309 -637 l37 -108 69
        0 69 0 0 880 0 880 -1865 0 -1865 0 0 -70z" className="svg-elem-4"></path>
            <path d="M17800 7980 l0 -70 428 0 428 0 1229 -3600 1230 -3600 251 0 251 0
        268 757 268 758 -35 105 c-20 58 -454 1327 -964 2820 -510 1493 -929 2725
        -932 2738 l-4 22 376 0 376 0 0 70 0 70 -1585 0 -1585 0 0 -70z" className="svg-elem-5"></path>
            <path d="M21810 7980 l0 -70 1545 0 1545 0 0 70 0 70 -1545 0 -1545 0 0 -70z" className="svg-elem-6"></path>
            <path d="M26850 7980 l0 -70 441 0 c415 0 441 -1 437 -17 -7 -27 -2510 -7167
        -2515 -7176 -3 -4 30 -7 72 -5 l77 3 1273 3595 1272 3595 417 3 416 2 0 70 0
        70 -945 0 -945 0 0 -70z" className="svg-elem-7"></path>
            <path d="M15402 6092 c-120 -490 -272 -795 -509 -1023 -135 -129 -269 -210
        -447 -268 -207 -68 -331 -81 -784 -81 l-363 0 3 -72 3 -73 465 -6 c499 -6 517
        -8 688 -65 274 -91 504 -291 672 -583 116 -205 198 -430 300 -828 l11 -43 64
        0 65 0 0 1605 0 1605 -63 0 -64 0 -41 -168z" className="svg-elem-8"></path>
            <path d="M17315 2958 c-2 -7 -17 -65 -34 -128 -261 -997 -884 -1697 -1695
        -1905 -276 -71 -237 -68 -1313 -72 l-973 -4 0 -70 0 -69 2075 0 2075 0 0 1130
        0 1130 -65 0 c-44 0 -67 -4 -70 -12z" className="svg-elem-9"></path>
          </g>
        </svg>
      </div>

      <div className="fixed bottom-0 p-5 flex justify-end w-full">
        <div>
          <a href="https://www.instagram.com/viewfilms.w/" target="_blank" rel="noreferrer" className="flex justify-between items-center text-xl">Instagram</a>
        </div>
      </div>
    </Router>
  );
}

export default App;
