import React from 'react'
import '../wedding.css'
import { sliderArr } from '../../../videos'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function FilmList() {
    const { t } = useTranslation();


    return (
        <div className="mx-auto max-w-2xl py-16 px-4 lg:max-w-7xl lg:gap-x-8 lg:px-8 mt-20">
            <h1 class="text-4xl mb-10 text-center underline underline-offset-8">Films</h1>
            <div>

                {sliderArr.map((item) => {
                    // <div
                    //     className="bg-white border rounded-lg overflow-hidden transform hover:translate-y-2 hover:shadow-xl transition duration-30 my-5"
                    //     key={item.id}
                    // >
                    //     <Link
                    //         to={`/wedding/film/${item.id}`}
                    //     >
                    //         <img
                    //             src={item.fallbackImageUrl}
                    //             alt={item.title}
                    //             className="w-full object-cover object-center h-[14rem]"
                    //         />
                    //         <div className="p-3 text-center">
                    //             <p className="text-sm text-gray-700">{item.title}</p>
                    //         </div>
                    //     </Link>
                    // </div>
                    const words = t(item.infos[2].content).split(/\s+/);

                    return (<section class="text-gray-700 body-font border-t border-gray-200">
                        <div class="container px-5 py-24 mx-auto flex flex-wrap items-center">
                            <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                                <Link to={`/wedding/film/${item.id}`}><img alt="feature" class="object-cover object-center" src={item.fallbackImageUrl} /></Link>
                            </div>
                            <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                                <div class="flex flex-col mb-10 lg:items-start items-center">
                                    <div class="flex-grow">
                                        <h2 class="text-gray-900 text-3xl title-font font-medium mb-3">{item.title}</h2>
                                        <p class="text-base text-justify" dangerouslySetInnerHTML={{ __html: words.slice(0, 50).join(" ") + (words.length > 200 ? "..." : "") }}></p>
                                        <Link to={`/wedding/film/${item.id}`} class="mt-3 text-indigo-500 inline-flex items-center cursor-pointer">{t("Read More")}
                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>)
                })}
            </div>
        </div>
    )
}
