import React, { useEffect, useState } from 'react'
import '../wedding.css'
import { useParams } from 'react-router-dom';
import { getVideoObjById } from '../../../videos';
import { useTranslation } from 'react-i18next';

export default function Film() {
  const { t } = useTranslation();
  let { id } = useParams();
  let [details, setDetails] = useState(null);

  useEffect(() => {
    setDetails(getVideoObjById(id));
  }, [])

  return (
    <div className="mx-auto max-w-2xl py-16 px-4 lg:max-w-7xl lg:gap-x-8 lg:px-8 mt-20">
      {
        details ?
          (<><iframe
            src={details.vimeoUrl}
            className="w-full bg-black"
            height="670"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Video Title"
          ></iframe>

            <h1 className="sm:text-5xl text-3xl font-vogue mt-20 uppercase">
              {details.title}
            </h1>

            <div className='line my-20'></div>

            <div className='sm:w-4/5 space-y-20'>


              {
                details.infos.map((info, key) =>
                (
                  <div key={key}>
                    <div className='flex lg:space-x-40 space-x-10 mb-20'>
                      <div className='film_content_title'>{t(info.title)}</div>
                      <div
                        className='film_content_description'
                        dangerouslySetInnerHTML={{ __html: t(info.content) }}
                      />
                    </div>
                    <div className='line'></div>
                  </div>
                ))
              }


            </div>
            <div className='mb-40'></div></>) : ''

      }

    </div>
  )
}
